<template>
  <div class="f-container">
    <transition name="flixFadeIn">
      <div class="save" :key="status" v-if="save">
        <div class="success flix-text-success"><flixIcon id="ok" /></div>
        <div class="flix-text-success">{{ $t('message.successfullSaved') }}</div>
      </div>
    </transition>
    <div><h4 class="flix-html-h4">{{ email }}</h4> </div>
    <a href="#" class="flix-html-a flix-html-small" @click.prevent="status = !status">
      <span :class="{'flix-text-danger': !status, 'flix-text-success': status}" class="icon"><flixIcon id="remove" v-if="!status" /> <flixIcon id="ok" v-else /></span>
      <span>{{ $t('message.notifyMeViaEmail') }}</span>
    </a>
    <a :href="$flix_url">
      <logo class="logo"/>
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      email: atob(this.$route.params.id),
      status: false,
      save: true
    }
  },
  computed: {

  },
  watch: {
    status () { this.changeStatus() }
  },
  mounted () {
    this.setSave()
  },
  methods: {
    changeStatus () {
      this.save = true
      setTimeout(function () {
        this.save = false
      }.bind(this), 3000)
      this.setSave()
    },
    setSave () {
      var data = {
        email: this.email
      }

      if (!this.status) {
        data.status = true
      }

      this.$flix_post({
        url: 'blacklist/set',
        data: data,
        callback: function () { }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
  .f-container
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    a.flix-html-a
      display: flex
      align-items: center
      justify-content: center
      flex-direction: row
      padding: 0
      span
        padding: 0
        margin: 0
        display: flex
        align-items: center
        justify-content: center
        text-decoration: underline
        &:first-of-type
          margin-right: 5px
          text-decoration: none
    .save
      text-align: center
      .success
        font-size: 4rem
    .logo
      margin-top: 30px
      width: 80px
</style>
